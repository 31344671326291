<template>
  <v-alert
    v-model="alert"
    close-label="Kapat"
    color="#313131"
    dark
    absolute
    class="cookie caption"
    rounded="0"
  >
    <v-container>
      <v-row no-gutters align="center">
        <v-col cols="12" sm="10">
          Sitemizi kullanarak, çerez kullanımına izin vermektesiniz. Daha fazla
          bilgi için
          <router-link :to="{ name: 'privacy-policy' }"
            >Çerez Politikası</router-link
          >
          sayfamızı ziyaret edebilirsiniz.
        </v-col>
        <v-col cols="12" sm="2" class="text-right">
          <v-btn color="primary" @click="alert = false" small elevation="0">
            Kapat
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      alert: true
    };
  }
};
</script>

<style lang="scss">
.cookie {
  position: fixed !important;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 99;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
