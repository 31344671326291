




























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  logo = require("@/assets/logo.svg");

  navDrawer = null;

  menuItems = [
    {
      title: "EĞİTİM KATALOĞU",
      to: {
        name: "course-catalog"
      }
    },
    {
      title: "NASIL YAPILIR?",
      to: {
        name: "faq"
      }
    }
  ];

  get userSignedIn() {
    return this.$store.getters["auth/userSignedIn"];
  }
}
