








































































































































import { Component, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import "@/types";

@Component
export default class TopBar extends Vue {
  get userSignedIn() {
    return this.$store.getters["auth/userSignedIn"];
  }

  get user(): User {
    return this.$store.getters["auth/user"];
  }

  get cartItemCount(): number {
    return this.$store.getters["cart/cartItemList"].length;
  }

  socialLinks = [
    {
      icon: "mdi-twitter",
      href: "https://twitter.com/kahev2018"
    },
    {
      icon: "mdi-facebook",
      href: "http://fb.me/kahev2018"
    },
    {
      icon: "mdi-youtube",
      href: "https://www.youtube.com/c/kahev2018"
    },
    {
      icon: "mdi-instagram",
      href: "https://www.instagram.com/kahev2018"
    }
  ];

  goTo(path: RawLocation) {
    this.$router.push(path).catch(() => {
      return false;
    });
  }

  signOut() {
    this.$store.dispatch("auth/signOut");
    this.$router
      .replace({
        name: "home"
      })
      .catch(() => {
        return false;
      });
  }
}
